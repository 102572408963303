
import { Vue, Component } from 'vue-property-decorator'
import * as echarts from 'echarts'
import UploadFile from '@/components/uploadFile/Index.vue'
import { FileInfo } from '@/types/uploadFile'
import Add from '../laborManage/taiBanLabor/Add.vue'
export interface Route {
  path: string;
}
@Component({
  components: { UploadFile, Add },
  filters: {
    imgListFormat (list: FileInfo[]) {
      const imgList = list.map(item => {
        return item.filePrefix + item.fileUrl
      })
      return imgList || []
    },
    issueStatus (val: any) {
      // 0待反馈 1待审核 2已完成 3待返工(驳回) 4 待确认 5 已关闭
      if (val === 1) {
        return '待反馈'
      } else if (val === 2) {
        return '待审核'
      } else if (val === 3) {
        return '已完成'
      } else {
        return '已关闭'
      }
    },
    imgListFormat2 (list: FileInfo[]) {
      const imgTypeList = ['jpeg', 'jpg', 'png']
      const imgList: Array<string> = []
      list.forEach((item: FileInfo) => {
        const str = item.fileName.split('.')
        if (imgTypeList.find((items) => items === str[str.length - 1].toLowerCase())) {
          imgList.push(item.filePrefix + item.fileUrl)
        }
      })
      return imgList || []
    },
    getName (str: any) {
      if (str.length > 10) {
        return str.substring(0, 10) + '...'
      } else {
        return str
      }
    }
  }
})
export default class Tree extends Vue {
  private projectList = []

  private tabs: any = []
  private tabIndex = 0
  private tabIndex1 = 0
  private tabs1 = ['领用', '入库']

  private imgList = ['jpeg', 'jpg', 'png']
  private videoList = ['mp4', 'mov']

  private formData = { // 项目id
    projectId: ''
  }

  private addShow = false

  private conservedays = { // 养护天数
    maintStartTime: '',
    runDay: ''
  }

  private todaysData = { // 今日事项
  }

  private attendanceData = { // 劳务管理
  }

  private conserveData = { // 养护作业
    workOrderMap: {

    }
  }

  private patrolData = { // 项目巡查
  }

  private MaterialData= { // 物料管理
    outMaterialTypeMap: {},
    inMaterialTypeMap: {}
  }

  private TaiBanData = { // 台班管理
  }

  private menuPermissionsObj= {
    attendance: false,
    conserve: false,
    materia: false,
    patro: false,
    TaiBan: false
  }

  private buttonPermissionsObj= {
    conserve: false,
    patro: false,
    TaiBan: false
  }

  get menuListAll () {
    return JSON.parse(window.sessionStorage.getItem('menuList') as any) || []
  }

  created () {
    // console.log('created')
    this.getProjectList()
    this.getMenuPermission(this.menuListAll, '/laborManageList/attendance')
    this.getMenuPermission(this.menuListAll, '/orderManage')
    this.getMenuPermission(this.menuListAll, '/patrol/patrolTask')
    this.getMenuPermission(this.menuListAll, '/property/materialmanage')
    this.getMenuPermission(this.menuListAll, '/laborManageList/taiBanLabor')
    this.getMenuPermission(this.menuListAll, '/myOrder')
  }

  getMenuPermission (menuList: any, target: any) {
    for (let i = 0; i < menuList.length; i++) {
      if (menuList[i]?.childNode?.length) {
        this.getMenuPermission(menuList[i].childNode, target)
      } else {
        if (menuList[i].url === target) {
          // flag = true
          if (target === '/laborManageList/attendance') {
            this.menuPermissionsObj.attendance = true
          } else if (target === '/orderManage') {
            this.menuPermissionsObj.conserve = true
          } else if (target === '/patrol/patrolTask') {
            this.menuPermissionsObj.patro = true
            this.buttonPermissionsObj.patro = menuList[i].button.some((item: any) => {
              return item.butCode === 'PLA_ADD_01'
            })
          } else if (target === '/property/materialmanage') {
            this.menuPermissionsObj.materia = true
          } else if (target === '/laborManageList/taiBanLabor') {
            this.menuPermissionsObj.TaiBan = true
            this.buttonPermissionsObj.TaiBan = menuList[i].button.some((item: any) => {
              return item.butCode === 'YMTC_ADD_01'
            })
          } else if (target === '/myOrder') {
            console.log('menuList[i]', menuList[i])
            this.buttonPermissionsObj.conserve = menuList[i].button.some((item: any) => {
              return item.butCode === 'YOD_ADD_01'
            })
          }
          break
        }
      }
    }
  }

  getProjectList () {
    // selectYhProjectByList
    // selectYhProjectAllByList
    this.$axios.get(this.$apis.project.selectYhProjectByList).then(res => {
      // console.log('获取项目', res)
      this.projectList = res || []
      // 项目为空处理未做
      this.formData.projectId = res[0].projectId
      this.getOtherData()
    })
  }

  // 获取其他模块数据
  getOtherData () {
    this.getDay() // 养护天数
    this.getTodayEvent() // 今日事项
    this.getAttendance() // 劳务管理
    this.getConserve() // 养护作业
    this.getpatro() // 项目巡查
    this.getMaterial() // 物料管理
    this.getTaiBan() // 台班管理
  }

  // 改变项目
  setProject () {
    this.getOtherData()
  }

  // 获取工单天数
  getDay () {
    // console.log('获取天数')
    this.$axios.get(this.$apis.workBench.selectRunDayselectRunDay, {
      projectId: this.formData.projectId
    }).then(res => {
      res.maintStartTime = this.$dayjs().format('YYYY-MM-DD')
      this.conservedays = res || {}
    })
  }

  // 获取今日事项
  getTodayEvent () {
    this.$axios.get(this.$apis.workBench.selectTodayEvent, {
      projectId: this.formData.projectId
    }).then(res => {
      // console.log('获取今日事项111', res)
      this.todaysData = res || {}
    })
  }

  // 获取考勤管理
  getAttendance () {
    this.$axios.get(this.$apis.workBench.selectAttendance, {
      projectId: this.formData.projectId
    }).then(res => {
      // res.attendanceList[0].onClockTime = '2023-09-28 05:37:45'
      this.attendanceData = res || {}
    })
  }

  // 获取养护作业
  getConserve () {
    this.$axios.get(this.$apis.workBench.selectWorkOrder, {
      projectId: this.formData.projectId
    }).then(res => {
      // console.log('获取养护作业', res)
      this.conserveData = res || {}
      this.tabs = []
      for (const i in res.workOrderMap) {
        this.tabs.push(i)
      }
      if (this.tabs.length > 0) {
        this.tabIndex = this.tabs[0]
      }
      // console.log('数据', this.conserveData)
      this.$nextTick(() => {
        this.getConserveEchars(res.completeOrderCount, res.unCompleteOrderCount)
      })
    })
  }

  // 获取项目巡查
  getpatro () {
    this.$axios.get(this.$apis.workBench.selectWo, {
      projectId: this.formData.projectId
    }).then(res => {
      // console.log('获取项目巡查', res)
      res.issueList.fileList = []
      this.patrolData = res || {}
      this.$nextTick(() => {
        this.getPatroEchars(res.completePointCount, res.unCompletePointCount, res.completePointPer)
      })
    })
  }

  // 获取物料管理
  getMaterial () {
    this.$axios.get(this.$apis.workBench.selectMateriel, {
      projectId: this.formData.projectId
    }).then(res => {
      // console.log('获取物料管理', res)
      this.MaterialData = res || {}
      // this.$nextTick(() => {
      //   this.getMateriaEchars(res.outMaterialTypeMap)
      // })
    })
  }

  // 获取台班管理
  getTaiBan () {
    this.$axios.get(this.$apis.workBench.selectMachinery, {
      projectId: this.formData.projectId
    }).then(res => {
      // console.log('获取物料管理', res)
      this.TaiBanData = res || {}
    })
  }

  loadMaterial (index: number) {
    this.tabIndex1 = index
    this.$nextTick(() => {
      this.getMateriaEchars(index === 0 ? this.MaterialData.outMaterialTypeMap : this.MaterialData.inMaterialTypeMap)
    })
  }

  getConserveEchars (finished: any, unfinished: any) {
  // 基于准备好的dom，初始化echarts实例
    // const myChart: any = echarts.init(document.getElementById('conserve_echars'));
    const myChart = echarts.init((this.$refs.conserve_echars) as any)

    const option = {
      tooltip: {
        trigger: 'item'
      },
      legend: {
        // bottom: '0%',
        // left: 'center'
        orient: 'vertical',
        left: '50%',
        top: 'center',
        icon: 'circle',
        itemWidth: 8,
        itemGap: 8,
        textStyle: {
          lineHeight: 24
        },
        formatter: (name: any) => {
          console.log('name', name)
          if (name === '已完成：') {
            return `已完成： ${finished}`
          } else {
            return `未完成： ${unfinished}`
          }
        }
      },
      series: [
        {
          // name: 'Access From',
          type: 'pie',
          radius: ['55%', '68%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2
          },
          center: ['23%', '50%'],
          // label: {
          //   show: true,
          //   position: 'center',
          //   // normal: {
          //   //   // \n可让文字居于牵引线上方，很关键
          //   //   //  {b}  代表显示的内容标题
          //   //   // {c}代表数据
          //   //   // formatter: ' {b}：{c} \n',
          //   formatter: () => {
          //     return `总数 \n\n${unfinished + finished}`
          //   }// 不显示文字
          // },
          label: {
            show: true,
            formatter: () => {
              return '{a|总数}\n' + '{b|' + (+unfinished + finished) + '}'
            },
            position: 'center',
            fontFamily: 'PingFangSC-Regular, PingFang SC',
            rich: {
              a: {
                fontSize: 12,
                fontWeight: 300,
                color: '#999999',
                height: 30
              },
              b: {
                fontSize: 18,
                fontWeight: 500,
                color: '#000000'
              }
            }
          },
          labelLine: {
            show: false
          },
          data: [
            { value: finished, name: '已完成：' },
            { value: unfinished, name: '未完成：' }
          ]
        }
      ]
    }
    // 绘制图表
    myChart.setOption(option)
  }

  getPatroEchars (finished: any, unfinished: any, completePointPer: any) {
  // 基于准备好的dom，初始化echarts实例
    // const myChart: any = echarts.init(document.getElementById('conserve_echars'));
    const myChart = echarts.init((this.$refs.patro_echars) as any)

    const option = {
      tooltip: {
        trigger: 'item'
      },
      legend: {
        bottom: '0%',
        left: 'center'
      },
      series: [
        {
          // name: 'Access From',
          type: 'pie',
          radius: ['66%', '81%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2
          },
          center: ['50%', '41%'],
          // label: {
          //   show: false,
          //   position: 'center',
          //   normal: {
          //     // \n可让文字居于牵引线上方，很关键
          //     //  {b}  代表显示的内容标题
          //     // {c}代表数据
          //     // formatter: ' {b}：{c} \n',
          //     formatter: '',
          //     borderWidth: 20,
          //     borderRadius: 4,
          //     padding: [0, -80],
          //     rich: {
          //       a: {
          //         color: '#333',
          //         fontSize: 12,
          //         lineHeight: 20
          //       },
          //       b: {
          //         fontSize: 12,
          //         lineHeight: 20,
          //         color: '#333'
          //       }
          //     }
          //   }
          // },
          label: {
            show: true,
            formatter: () => {
              return '{a|完成率}\n' + '{b|' + (+completePointPer) + '%}'
            },
            position: 'center',
            fontFamily: 'PingFangSC-Regular, PingFang SC',
            rich: {
              a: {
                fontSize: 12,
                fontWeight: 300,
                color: '#999999',
                height: 30
              },
              b: {
                fontSize: 18,
                fontWeight: 500,
                color: '#000000'
              }
            }
          },
          labelLine: {
            show: false
          },
          data: [
            { value: finished, name: '已巡查' },
            { value: unfinished, name: '未巡查' }
          ]
        }
      ]
    }
    // 绘制图表
    myChart.setOption(option)
  }

  getMateriaEchars (data: any) {
  // 基于准备好的dom，初始化echarts实例
    // const myChart: any = echarts.init(document.getElementById('conserve_echars'));
    const myChart = echarts.init((this.$refs.material_echars) as any)

    const option = {
      tooltip: {
        trigger: 'item'
      },
      legend: {
        // bottom: '0%',
        left: 'center',
        formatter: function (name: any) {
          return name.length > 4 ? name.substr(0, 5) + '...' : name
        },
        top: '65%',
        type: 'scroll'
      },
      series: [
        {
          type: 'pie',
          radius: ['40%', '50%'],
          center: ['50%', '32%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2
          },
          label: {
            show: false,
            position: 'center',
            normal: {
              // \n可让文字居于牵引线上方，很关键
              //  {b}  代表显示的内容标题
              // {c}代表数据
              // formatter: ' {b}：{c} \n',
              formatter: function (param: any) {
                // console.log('text', param)
                const text = param.data.name
                if (text.length < 6) {
                  return text + '：' + param.data.value + '\n'
                } else {
                  return text.substring(0, 5) + '...' + '：' + param.data.value + '\n'
                }
              },

              borderWidth: 20,
              borderRadius: 4,
              padding: [0, -80],
              rich: {
                a: {
                  color: '#333',
                  fontSize: 12,
                  lineHeight: 20
                },
                b: {
                  fontSize: 12,
                  lineHeight: 20,
                  color: '#333'
                }
              }
            }
          },
          labelLine: {
            show: false
          },
          data: Object.keys(data).map((item) => {
            return { name: item, value: data[item] }
          })
          // height: 300 + 'px'
        }
      ]
    }
    // 绘制图表
    myChart.setOption(option)
    myChart.getDom().style.height = 300 + 'px' // 高度动态设定
  }

  loadWorkDetail (index: any) {
    this.tabIndex = index
  }

  clickmastshadow () {
    this.$nextTick(() => {
      (this.$refs.preview as any).click();
      (this.$refs.preview as any).clickHandler()
    })
  }

  goAddOrder () {
    this.$router.push({
      path: '/myOrder/add'
      // query: {
      //   projectId: this.formData.projectId
      // }
    })
  }

  goAttendance () {
    console.log('goAttendance')
    if (!this.menuPermissionsObj.attendance) {
      // this.$message.success
      this.$message.warning('无此页面权限，请先配置权限')
      return
    }
    this.$router.push({
      name: 'attendance'
    })
  }

  goOrder () {
    // console.log('goOrder')
    if (!this.menuPermissionsObj.conserve) {
      // this.$message.success
      this.$message.warning('无此页面权限，请先配置权限')
      return
    }
    this.$router.push({
      name: 'orderManage'
    })
  }

  goPatrol () {
    // console.log('goAttendance')
    if (!this.menuPermissionsObj.patro) {
      // this.$message.success
      this.$message.warning('无此页面权限，请先配置权限')
      return
    }
    this.$router.push({
      path: '/patrol/patrolTask'
    })
  }

  addPatrol () {
    this.$router.push({
      path: '/patrol/patrolTask/add'
    })
  }

  goMaterial () {
    if (!this.menuPermissionsObj.materia) {
      // this.$message.success
      this.$message.warning('无此页面权限，请先配置权限')
      return
    }
    this.$router.push({
      path: '/property/materialmanage'
    })
  }

  goTaiBan () {
    if (!this.menuPermissionsObj.TaiBan) {
      // this.$message.success
      this.$message.warning('无此页面权限，请先配置权限')
      return
    }
    this.$router.push({
      path: '/laborManageList/taiBanLabor'
    })
  }

  addTaiBan () {
    this.addShow = true
  }

  // 今日事项是否完成
  isFinished (val: any) {
    // console.log('图片处理', val)
    if (val > 0) {
      return require('../../assets/img/overview/finished.svg')
    } else {
      return require('../../assets/img/overview/unfinished.png')
    }
  }

  // 今日事项右上角完成状态
  wordStatus (val1: any, val2: any) {
    // console.log('wordStatus', val1, val2)
    // 如果只传一个值就是台班
    if (val2 === undefined) {
      if (val1 > 0) {
        return require('../../assets/img/overview/green.svg')
      } else {
        return require('../../assets/img/overview/red.svg')
      }
    }
    // 否则就不是台班
    if (val1 > 0 && val2 > 0) {
      return require('../../assets/img/overview/green.svg')
    } else if (val1 === 0 && val2 === 0) {
      return require('../../assets/img/overview/red.svg')
    } else {
      return require('../../assets/img/overview/orange.png')
    }
  }

  riseOrdecline (v2: any, v13: any) {
    if (v2 >= v13) {
      return require('../../assets/img/overview/rise.png')
    } else {
      return require('../../assets/img/overview/decline.png')
    }
  }

  bgClass (val1: any, val2: any) {
    // console.log('bgClass', val1, val2)
    // 如果只传一个值就是台班
    if (val2 === undefined) {
      if (val1 > 0) {
        return 'bggreen'
      } else {
        return 'bgred'
      }
    }
    // 否则就不是台班
    if (val1 > 0 && val2 > 0) {
      return 'bggreen'
    } else if (val1 === 0 && val2 === 0) {
      return 'bgred'
    } else {
      return 'bgorange'
    }
  }

  bdClass (val1: any, val2: any) {
    // console.log('bgClass', val1, val2)
    // 如果只传一个值就是台班
    if (val2 === undefined) {
      if (val1 > 0) {
        return 'bdgreen'
      } else {
        return 'bdred'
      }
    }
    // 否则就不是台班
    if (val1 > 0 && val2 > 0) {
      return 'bdgreen'
    } else if (val1 === 0 && val2 === 0) {
      return 'bdred'
    } else {
      return 'bdorange'
    }
  }
}
